<template>
  <div>
    <Toolbar class="mb-4">
      <template #left>
        <!-- <Button
          label="Add New"
          icon="pi pi-plus"
          class="ml-2 p-button-success"
          @click="$router.push('contact/add')"
        /> -->
        <!-- <Button
          label="حذف"
          icon="pi pi-trash"
          @click="deleteAll"
          :disabled="selectedItems.length == 0"
          class="p-button-danger"
        /> -->
      </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
        />
        <!-- <Button icon="pi pi-search" class="ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">

قائمه اتصل بنا
          <div style="text-align: left">
            <!-- <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Select Columns"
              style="width: 20em"
            /> -->
          </div>
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>

      <Column
        filterMatchMode="contains"
        field="id"
        header="#"
        :sortable="true"
      >
        
        
      <template #filter>
          <InputText
            v-model="filters['id']"
            class="p-column-filter"
            filterMatchMode="contains"
            placeholder="بحث ب #"
          />
        </template>
        <template #body="slotProps">
          <p v-if="slotProps.data.messageReply" style="color: green">
            {{ slotProps.data.id }}
          </p>
          <p v-else style="color: crimson">
            {{ slotProps.data.id }}
          </p>
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="name"
        header="الاسم"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['name']"
            class="p-column-filter"
            filterMatchMode="contains"
            placeholder="بحث بالاسم"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="phone"
        header="الهاتف"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['phone']"
            class="p-column-filter"
            placeholder="بحث بالهاتف"
          />
        </template>
      </Column>
      <Column
        filterMatchMode="contains"
        field="email"
        header="الايميل"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="email"
            v-model="filters['email']"
            class="p-column-filter"
            placeholder="بحث بالايميل"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="subject"
        header="الموضوع"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="subject"
            v-model="filters['subject']"
            filterMatchMode="contains"
            class="p-column-filter"
            placeholder="بحث بالموضوع"
          />
        </template>
      </Column>

      <Column
        filterMatchMode="contains"
        field="createdAt"
        header="تاريخ الارسال"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['createdAt']"
            class="p-column-filter"
            placeholder="بحث بتاريخ الارسال"
          />
        </template>
        <template #body="slotProps">
          {{ $durationFormatDate(slotProps.data.createdAt) }}
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty> No Data </template>

            <Column
              filterMatchMode="contains"
              field="message"
              header="الرساله"
            />
          </DataTable>

          <DataTable
            :value="[slotProps.data]"
            v-if="slotProps.data.messageReply"
            class="p-datatable-customers table table-striped"
          >
            <template #empty> No Data </template>

            <Column
              filterMatchMode="contains"
              field="messageReply"
              header="الرد"
            />
          </DataTable>
          <Button
            v-else
            label="رد"
            @click="openDialog(slotProps.data)"
            icon="pi pi-plus"
            class="ml-2 p-button-success"
          />
        </div>
      </template>
    </DataTable>

    <Dialog
      header="ارسال رد"
      :visible.sync="displayProductsAdd"
      :style="{ width: '50vw' }"
    >
      <div class="p-m-0">
        <div class="mb-3">
          <label for="messageReply" class="form-label required">
            الرساله
            <span>*</span>
          </label>
          <textarea class="form-control" v-model="d.messageReply" />
        </div>
      </div>
      <template #footer>
        <Button
          label="ارسال"
          icon="pi pi-check"
          @click="addProducts()"
          autofocus
        />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="cDialog()"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [],
      selectedItems: [],

      expandedRows: [],
      loading: true,
      filters: {},
      displayProductsAdd: false,
      d: {
        id: null,
        messageReply: null,
        name: null,
        subject: null,
      },
    };
  },
  methods: {
    getData() {
      this.$http.get(`contactUs`).then(
        (response) => {
          this.loading = false;
          this.list = response.data.data;
        },
        (err) => {
          this.loading = false;
          this.$toast.add({
            severity: 'error',
            summary: 'هناك خطأ',
            detail: err.response.data.message,
            life: 3000,
          });
        },
      );
    },
    addProducts() {
      if (this.d.messageReply) {
        this.displayProductsAdd = false;
        this.$http.put(`contactUs/${this.d.id}`, this.d).then(
          (response) => {
            this.d = {
              messageReply: null,
              
            };
            this.getData();
            this.$toast.add({
              severity: 'success',
              summary: 'Success',
              detail: 'Data Added Successfully',
              life: 3000,
            });
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
            detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      }
    },
    openDialog(data) {
      this.displayProductsAdd = true;
      this.d = { ...data };
    },
    cDialog() {
      this.displayProductsAdd = false;
      this.d = {
        id: null,
        messageReply: null,
        name: null,
        subject: null,
      };
    },
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },

  
    
  },
  created() {
    this.getData();
    this.selectedColumns = this.columns;
  },
};
</script>

<style></style>

